(function ($) {
  Drupal.behaviors.enter_to_win_v1 = {
    attach: function (context, settings) {
      var enterToWinEmailForm = function () {
        var $enterToWinEmailFormContainer = $('.js-enter-to-win-email-container'),
          $enterToWinMobileContainer = $('.js-enter-to-win-mobile-container'),
          $enterToWinCheckboxContainer = $enterToWinEmailFormContainer.find('.js-enter-to-win-checkbox-wrapper'),
          $enterToWinCheckboxMobileContainer = $enterToWinMobileContainer.find('.js-enter-to-win-checkbox-wrapper'),
          $enterToWinTC = $enterToWinCheckboxContainer.find('.js-enter-to-win-tc'),
          $enterToWinTCMobile = $enterToWinCheckboxMobileContainer.find('.js-enter-to-win-tc'),
          $enterToWinStay = $enterToWinCheckboxContainer.find('.js-enter-to-win-stay-with-origins'),
          $enterToWinStayMobile = $enterToWinCheckboxMobileContainer.find('.js-enter-to-win-stay-with-origins');

        // checkbox auto select
        $enterToWinCheckboxContainer.find('.js-enter-to-win-tc-label').click(function () {
          $enterToWinTC.click();
          $enterToWinTCMobile.click();
        });

        $enterToWinCheckboxContainer.find('.js-enter-to-win-stay-with-origins-label').click(function () {
          $enterToWinStay.click();
          $enterToWinStayMobile.click();
        });

        // Slide down email section
        $('.js-maskimizer-enter-to-win-wrapper .flex-tout__inner .flex-tout--img__upper-image').click(function (e) {
          e.preventDefault();
          $enterToWinEmailFormContainer.slideDown(function () {
            var $thisContainer = $(this);

            $thisContainer.removeClass('enter_to_win_disable');
            if (navigator.userAgent.match(/iPad/i) != null && Math.abs(window.orientation) == 90) {
              $('.js-enter-to-win-pc-container').addClass('iPad_landscape');
            }
            $thisContainer[0].style.removeProperty('overflow');
            $('html,body').animate(
              {
                scrollTop:
                  $('.js-enter-to-win-pc-container').offset().top - $('.js-enter-to-win-pc-container').outerHeight(true)
              },
              'slow'
            );
          });
        });

        // slide up email section
        $enterToWinEmailFormContainer.find('.js-enter-to-win-close').click(function () {
          $enterToWinEmailFormContainer.slideUp(function () {
            $(this).addClass('enter_to_win_disable').removeClass('iPad_landscape');
          });
        });

        // Auto fill email ID
        $enterToWinEmailFormContainer.find('.js-enter-to-win-email-wrapper input').keyup(function () {
          var this_value = $(this).val();

          if ($(this).closest('.js-enter-to-win-mobile-container').length) {
            $('.js-enter-to-win-pc-container .js-enter-to-win-email-wrapper input').val(this_value);
          } else {
            $('.js-enter-to-win-mobile-container .js-enter-to-win-email-wrapper input').val(this_value);
          }
        });

        // T&C validation
        $('.js-enter-to-win-tc').change(function () {
          $enterToWinSubmit = $('.js-enter-to-win-submit-wrapper').find('input');
          $(this).is(':checked')
            ? $enterToWinSubmit.removeClass('enter-to-win__greyout').prop('disabled', false)
            : $enterToWinSubmit.addClass('enter-to-win__greyout').prop('disabled', true);
        });
      };
      var $enterToWinMaskimizer = $('#enter-to-win-maskimizer');
      var $maskimizerEmailSection = $('.js-maskimizer-email-field-wrapper');

      // add class to enter to win 50/50 block
      $enterToWinMaskimizer
        .closest('.flex-grid__item')
        .addClass('maskimizer-enter-to-win-wrapper js-maskimizer-enter-to-win-wrapper');

      // add classes to maskimizer page
      $maskimizerEmailSection.closest('.origins__milestone').addClass('maskimizer__email-form-container');
      $('.js-maskimizer-step1').closest('.origins__milestone').addClass('maskimizer__step1-container');
      $('.js-maskimizer-step2').closest('.origins__milestone').addClass('maskimizer__step2-container');

      var template = 'enter_to_win';

      if (site.templates[template] !== undefined) {
        var enter_to_win_data = site.template.get({
          name: template,
          data: site.templates[template].data
        });

        // Enter to win email section for PC
        $enterToWinMaskimizer.closest('.basic-formatter__item').after(
          $('<li>', {
            class:
              'basic-formatter__item enter-to-win-email-container js-enter-to-win-email-container js-enter-to-win-pc-container enter_to_win_disable clearfix enter-to-win__pc',
            html: enter_to_win_data
          })
        );
        // Enter to win email section for mobile
        $('.js-maskimizer-enter-to-win-wrapper').after(
          $('<div>', {
            class:
              'enter-to-win-email-container js-enter-to-win-email-container js-enter-to-win-mobile-container enter-to-win__mobile enter_to_win_disable clearfix',
            html: enter_to_win_data
          })
        );
        // Enter to win section for SPP
        $maskimizerEmailSection.html(enter_to_win_data);
        enterToWinEmailForm();
      }

      // rpc call
      var $enterToWinV1 = $('.js-enter-to-win-outer-wrapper');
      var enterToWinAlert = $enterToWinV1.find('.js-enter-to-win-alert');
      var enterToWinForm = $enterToWinV1.find('form');

      enterToWinForm.submit(function (e) {
        e.preventDefault();

        var form = this;
        var fields = ['_SUBMIT', 'PC_EMAIL_ADDRESS', 'PROMOTION_ID', 'PC_EMAIL_PROMOTIONS', 'TEXT_ANSWER'];
        var paramObj = {};

        $.each(fields, function (i, name) {
          var field = $('input[name="' + name + '"]', form),
            undef;

          if (field != undef && field.length > 0) {
            paramObj[name] =
              field[0].type == 'radio' || field[0].type == 'checkbox' ? (field.is(':checked') ? 1 : 0) : field.val();
          }
        });

        generic.jsonrpc.fetch({
          method: 'rpc.form',
          params: [paramObj],
          onSuccess: function (jsonRpcResponse) {
            // hide the form and alert container
            enterToWinAlert.hide();
            enterToWinForm.hide();

            // show the thank you content
            $enterToWinV1.find('.js-enter-to-win-thankyou').removeClass('hidden');
          },
          onFailure: function (jsonRpcResponse) {
            var messages = jsonRpcResponse.getMessages();
            // Get the error messages from the rpc response
            var tmp = [],
              out = '';

            $.each(messages, function (i, message) {
              tmp.push(message.text);
            });
            if (tmp.length > 0) {
              out = tmp.join('<br />');
            }

            // Populate the error message
            enterToWinAlert.html(out);
          }
        });
      });
    }
  };
})(jQuery);
